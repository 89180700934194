<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Add a New Itinerary</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="itinerary-form">
          <v-text-field
            label="Title"
            v-model="fields.title"
            background-color="white"
            outlined
            dense
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="itinerary-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      fields: {
        title: "",
      },
      itinerary: {},
      errors: {},
    };
  },

  methods: {
    openForm: function (itinerary = null) {
      if (itinerary !== null) {
        this.fields.title = itinerary.title + " (Copy)";
        this.fields.itinerary_id = itinerary.id;
        this.itinerary = itinerary;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.fields = {
        title: "",
      };
      this.itinerary = {};
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      this.loading = true;
      this.errors = {};

      this.$store
        .dispatch("drum/tours/saveItinerary", {
          appId,
          tourId,
          isEditing: false,
          fields: this.fields,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>