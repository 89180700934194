<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Itineraries</h2>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn
            class="mr-2"
            small
            depressed
            dark
            @click="$refs.itineraryForm.openForm()"
          >
            <v-icon left>mdi-plus</v-icon>Add New
          </v-btn>

          <v-btn
            small
            depressed
            dark
            :to="{
              name:
                tour.status === 'tour'
                  ? 'module-drum-tours-individual-itineraries-archived'
                  : 'module-drum-proposals-individual-itineraries-archived',
            }"
          >
            <v-icon left>mdi-archive</v-icon>View Archived
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="tour.itineraries"
      no-data-text="There aren't any itineraries"
    >
      <template v-slot:item.title="{ item }">
        <router-link
          :to="{
            name:
              tour.status === 'tour'
                ? 'module-drum-tours-itinerary'
                : 'module-drum-proposals-itinerary',
            params: { itineraryId: item.id },
          }"
          >{{ item.title }}</router-link
        >
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ formatDateTime(item.updated_at, true) }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          label
          small
          :color="
            item.status === 'Draft'
              ? 'blue'
              : item.status === 'Accepted'
              ? 'orange'
              : 'green'
          "
          text-color="white"
          >{{ item.status }}</v-chip
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="blue lighten-4 blue--text"
              class="mr-2"
              v-on="on"
              :href="getQuoteUrl(item.unique_id)"
              target="_blank"
            >
              <v-icon small>mdi-monitor-dashboard</v-icon>
            </v-btn>
          </template>
          <span>See Customer's View</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="orange lighten-4 orange--text"
              class="mr-2"
              v-on="on"
              @click="$refs.itineraryForm.openForm(item)"
            >
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Create a Duplicate</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="green lighten-4 green--text"
              class="mr-2"
              v-on="on"
              :to="{
                name: 'module-drum-proposals-itinerary',
                params: { itineraryId: item.id },
              }"
            >
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>View</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="red lighten-4 red--text"
              v-on="on"
              @click="archiveItinerary(item)"
            >
              <v-icon small>mdi-archive</v-icon>
            </v-btn>
          </template>
          <span>Archive Itinerary</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog
      v-model="archiveDialog.open"
      max-width="400"
      @click:outside="resetArchiveDialog()"
    >
      <v-card>
        <v-card-title class="headline">Archive Itinerary</v-card-title>
        <v-card-text>
          Are you sure you want to archive
          {{ archiveDialog.itinerary.title }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetArchiveDialog()"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="archiveDialog.loading"
            @click="confirmArchiveItinerary()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ItineraryForm ref="itineraryForm" />
  </div>
</template>

<script>
import ItineraryForm from "../components/ItineraryForm";

export default {
  components: {
    ItineraryForm,
  },

  data() {
    return {
      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Last Updated", value: "formatted_dates.updated_at" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      archiveDialog: {
        open: false,
        loading: false,
        itinerary: {},
        error: false,
      },
    };
  },

  computed: {
    tour() {
      return this.$store.state.drum.tours["tour"];
    },
  },

  methods: {
    archiveItinerary: function(itinerary) {
      this.archiveDialog.itinerary = itinerary;
      this.archiveDialog.open = true;
    },
    resetArchiveDialog: function() {
      this.archiveDialog.open = false;
      this.archiveDialog.itinerary = {};
      this.archiveDialog.loading = false;
      this.archiveDialog.error = false;
    },
    confirmArchiveItinerary: function() {
      const appId = this.$route.params.id;
      const tourId = this.archiveDialog.itinerary.tour_id;
      const id = this.archiveDialog.itinerary.id;

      this.archiveDialog.loading = true;
      this.archiveDialog.error = false;

      this.$store
        .dispatch("drum/tours/deleteItinerary", {
          appId,
          tourId,
          id,
        })
        .then(() => {
          this.resetArchiveDialog();
        })
        .catch(() => {
          this.archiveDialog.loading = false;
          this.archiveDialog.error = true;
        });
    },
    getQuoteUrl(unique_id) {
      return process.env.VUE_APP_DRUMGOLF_URL + unique_id;
    },
  },
};
</script>
