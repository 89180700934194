var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h2',[_vm._v("Itineraries")])]),_c('v-col',[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","depressed":"","dark":""},on:{"click":function($event){return _vm.$refs.itineraryForm.openForm()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add New ")],1),_c('v-btn',{attrs:{"small":"","depressed":"","dark":"","to":{
            name:
              _vm.tour.status === 'tour'
                ? 'module-drum-tours-individual-itineraries-archived'
                : 'module-drum-proposals-individual-itineraries-archived',
          }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-archive")]),_vm._v("View Archived ")],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tour.itineraries,"no-data-text":"There aren't any itineraries"},scopedSlots:_vm._u([{key:"item.title",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
          name:
            _vm.tour.status === 'tour'
              ? 'module-drum-tours-itinerary'
              : 'module-drum-proposals-itinerary',
          params: { itineraryId: item.id },
        }}},[_vm._v(_vm._s(item.title))])]}},{key:"item.updated_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.updated_at, true))+" ")]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{attrs:{"label":"","small":"","color":item.status === 'Draft'
            ? 'blue'
            : item.status === 'Accepted'
            ? 'orange'
            : 'green',"text-color":"white"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","depressed":"","color":"blue lighten-4 blue--text","href":_vm.getQuoteUrl(item.unique_id),"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-monitor-dashboard")])],1)]}}],null,true)},[_c('span',[_vm._v("See Customer's View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","depressed":"","color":"orange lighten-4 orange--text"},on:{"click":function($event){return _vm.$refs.itineraryForm.openForm(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Create a Duplicate")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","depressed":"","color":"green lighten-4 green--text","to":{
              name: 'module-drum-proposals-itinerary',
              params: { itineraryId: item.id },
            }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","depressed":"","color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.archiveItinerary(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-archive")])],1)]}}],null,true)},[_c('span',[_vm._v("Archive Itinerary")])])]}}])}),_c('v-dialog',{attrs:{"max-width":"400"},on:{"click:outside":function($event){return _vm.resetArchiveDialog()}},model:{value:(_vm.archiveDialog.open),callback:function ($$v) {_vm.$set(_vm.archiveDialog, "open", $$v)},expression:"archiveDialog.open"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Archive Itinerary")]),_c('v-card-text',[_vm._v(" Are you sure you want to archive "+_vm._s(_vm.archiveDialog.itinerary.title)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":function($event){return _vm.resetArchiveDialog()}}},[_vm._v("No, cancel")]),_c('v-btn',{attrs:{"color":"success","depressed":"","loading":_vm.archiveDialog.loading},on:{"click":function($event){return _vm.confirmArchiveItinerary()}}},[_vm._v("Yes")])],1)],1)],1),_c('ItineraryForm',{ref:"itineraryForm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }